export const MenuItems = [
  {
    title: "Swap",
    url: "/CookieSwap/",
    cName: "nav-links",
  },
  {
    title: "Liquidity",
    url: "/CookieSwap/liquidity",
    cName: "nav-links",
  },
  
];
